import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "@emotion/styled";
import tw from "twin.macro";

import Slider from "../Slider/SliderTestimonials";

const StyledSlider = styled.div`
   ${tw`relative`}/* .slick-prev,
    .slick-next {
      ${tw`border-white`}
      i {
         ${tw`text-white`}
      }
   } */
   /* .slick-dots {
      ${tw`left-0 relative! mt-6`}
      li {
         &.slick-active {
            button {
               ${tw`bg-white`}
            }
         }
      }
      button {
         ${tw`bg-white bg-opacity-40`}
      }
   } */
   /* blockquote {
      ${tw`max-w-4xl mx-auto`}
   } */
   /* q {
      ${tw`text-white mb-6 md:mb-10`}
   } */
   /* footer {
      ${tw`mt-10 md:mt-22`}
      span {
         ${tw`block text-white text-opacity-90 font-heading font-bold`}
      }
      cite {
         ${tw`text-sm md:text-base uppercase opacity-80 not-italic`}
      }
   } */
`;

const Testimonial = ({ className }) => {
   const data = useStaticQuery(graphql`
      {
         quotes: file(relativePath: { eq: "repeating/testimonials/Quotes.svg" }) {
            publicURL
         }
         stars: file(relativePath: { eq: "repeating/testimonials/Stars.svg" }) {
            publicURL
         }
      }
   `);

   return (
      <section className={`bg-white ${className}`}>
         <div className="container">
            <StyledSlider>
               <div className="max-w-3xl mx-auto text-center">
                  <img src={data.quotes.publicURL} alt="Quotes" className="mb-10 mx-auto" />
                  <Slider>
                     <div>
                        <blockquote>
                           <q className="mobile-text-xl md:text-xl">
                              Juan with Smart Accounting Hub has helped set up and manage the bookkeeping for two of my businesses. We've been working
                              together for about 8 years (6 years with one business and 2 years with another). There's almost no one I trust more, and
                              have come to rely on more, than Juan. You're in good hands.
                           </q>
                           <footer className="mt-10">
                              <img src={data.stars.publicURL} alt="5 Stars" className="mb-4 mx-auto" />
                              <cite className="text-sm not-italic">
                                 <span className="font-medium tracking-wider uppercase block">Patrick Dillon</span> Smart Accounting Hub Client
                              </cite>
                           </footer>
                        </blockquote>
                     </div>
                  </Slider>
               </div>
            </StyledSlider>
         </div>
      </section>
   );
};

export default Testimonial;
