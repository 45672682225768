import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const Services = ({ className, contentAlignment }) => {
   const data = useStaticQuery(graphql`
      {
         tenYearsExperience: file(relativePath: { eq: "repeating/why-us/10+ Years Experience.svg" }) {
            publicURL
         }
         friendlyService: file(relativePath: { eq: "repeating/why-us/Friendly service.svg" }) {
            publicURL
         }
         transparencyHonesty: file(relativePath: { eq: "repeating/why-us/Transparency _ honesty.svg" }) {
            publicURL
         }
      }
   `);

   return (
      <section className={`bg-gray-100 py-20 md:py-32 ${className}`}>
         <div className="container">
            <header className={`mb-16 md:mb-20 max-w-3xl text-center mx-auto`}>
               <h2>Why Get Smart?</h2>
            </header>

            <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-x-8 lg:gap-x-12 gap-y-12 md:gap-y-16 text-center`}>
               <div>
                  <img className={`mb-6 mx-auto`} src={data.tenYearsExperience.publicURL} alt="10+ Years Experience" />
                  <h3 className="heading-four">10+ Years Experience</h3>
                  <p className="mb-0">We have over a decade of experience helping small business owners in every industry.</p>
               </div>
               <div>
                  <img className={`mb-6 mx-auto`} src={data.transparencyHonesty.publicURL} alt="Transparency & honesty" />
                  <h3 className="heading-four">Transparency & Honesty</h3>
                  <p className="mb-0">Transparency leads to health. This is true for finances and our business partnership.</p>
               </div>
               <div>
                  <img className={`mb-6 mx-auto`} src={data.friendlyService.publicURL} alt="Friendly Service" />
                  <h3 className="heading-four">Friendly Service</h3>
                  <p className="mb-0">We make small business accounting easy with our expertise and friendly service.</p>
               </div>
            </div>
         </div>
      </section>
   );
};

export default Services;
